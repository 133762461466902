import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Text, Image } from "../components/Core"
import { Section, Columns } from "../components/Containers"

const PodcastQuery = ({ data, pageContext, location }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const { language } = pageContext

  const podcasts = data.podcasts.nodes

  return (
    <Layout className="podcast" language={language}>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        pathname={location.pathname}
        lang={language}
      />

      <Section zeroBottom colorBack className="podcast__clip relative">
        <Columns sideColumnsSize={2} className="pq__hero">
          <div className="column pq__heading mb-3--mobile">
            <Text className="px-40--mobile" as="h1" text={post.heading} />
            <Text text={post.blurb} />
          </div>
          <div className="column is-2"></div>
          <div className="column">
            <Image publicId={post.heroImage} />
          </div>
        </Columns>
      </Section>

      <Section>
        <Columns sideColumnsSize={2}>
          <div className="column">
            <Text as="h2" className="color-white" text={post.podcastsHeading} />
          </div>
          <div className="column is-one-third"></div>
        </Columns>

        <Columns sideColumnsSize={2}>
          <div className="column">
            <div className="pq__list">
              {podcasts.map(podcast => (
                <div key={podcast.title} className="pq__card">
                  <Text
                    className="pq__card-ep px-0"
                    as="p"
                    text={`Episode ${podcast.episode}`}
                  />
                  <Text
                    className="pq__card-heading px-0"
                    as="h3"
                    text={podcast.heading}
                  />
                  <Text
                    className="pq__card-description px-0"
                    as="p"
                    text={podcast.description}
                  />
                  <Link
                    className="pq__card-btn"
                    to={"/" + podcast.title}
                    title="Listen to the full episode">
                    Listen to Episode
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </Columns>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query podcastQueryPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heading
        blurb
        heroImage
        podcastsHeading
      }
    }
    podcasts: allPodcastsJson(sort: { fields: episode }) {
      nodes {
        episode
        heading
        title
        description
      }
    }
  }
`

export default PodcastQuery
